<template>

  <v-container
    fluid
    tag="section"
  >

  <v-dialog  v-model="dlg_lista"  width="500">
    <v-card>
      
  
      <v-card-actions>
        <v-spacer></v-spacer>
  
        <v-btn
          color="secundary" 
          text
          @click="dlg_lista = false"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>




  <v-dialog  v-model="dlg_delete"  width="500">
    <v-card>
      <v-card-title class="text-h5 red lighten-2">
        Mensagem
      </v-card-title>
  
      <v-card-text>
          Deseja deletar o registro?
      </v-card-text>
  
      <v-divider></v-divider>
  
      <v-card-actions>
        <v-spacer></v-spacer>
  
        <v-btn
          color="secundary" 
          text
          @click="dlg_delete = false"
        >
          Cancelar
        </v-btn>
  
        <v-btn
          color="red"
          @click="setConfirmDelete"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


<v-dialog  v-model="alert_msg"  width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Mensagem
        </v-card-title>

        <v-card-text> 
            {{msg_dialog}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="alert_msg = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>




<base-material-card
      icon="mdi-briefcase"
      title="Departamento"
      class="px-5 py-3">
<v-form ref="form" enctype="multipart/form-data">
    <v-card elevation="0">

    <v-card-text >
      <v-container>
        <v-row>
      
          <v-col cols="6" sm="6">
            <v-text-field 
              label="Descrição *" Required :rules="[v => !!v || 'Campo Obrigatório']" style="margin-left:4px;"
              filled v-model="departamento.descricao" x-large
              prepend-inner-icon="mdi-account-box"
            ></v-text-field>

            </v-col>

            <v-col cols="6" sm="6">
              <v-select label="Empresa *" v-model="departamento.empresa"  :items="empresas"  Required :rules="[v => !!v || 'Campo Obrigatório']" filled prepend-inner-icon="mdi-bookmark"></v-select>
             </v-col>

         
          

        </v-row>
        * campos obrigatórios
                     

                          

      </v-container> 

    </v-card-text>

    <v-card-actions class="justify-end">
      <v-btn color="success" :loading="btn_load" @click="salva()">
        Confirmar
        <v-icon right dark>mdi-check</v-icon>
      </v-btn>
    </v-card-actions>
    </v-card>


   
    <v-divider></v-divider>
    <br/>
    <v-data-table
        :headers="headers"
        :items="departamentos"
        :items-per-page="5" :footer-props="{
          'items-per-page-options': [5],
          'items-per-page-text':'Registro por página'
        }"
        class="elevation-0">

                  <template v-slot:item="row">
                    <tr>
                      <td style="width: 50%;font-size:16px;">{{row.item.descricao}} </td>
                      <td style="width: 40%;font-size:16px;">{{row.item.empresa}} </td>

                      <td style="width: 10%;font-size:14px;">
                        <v-progress-circular v-if="loading_download == true && row.item.id == n_download"
                      indeterminate
                      color="primary"
                        ></v-progress-circular>

                        <v-tooltip bottom >    
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mx-2"   fab  dark   small v-bind="attrs" v-on="on" @click="setDelete(row.item)" 
                            color="red">
                              <v-icon dark>
                                mdi-delete
                              </v-icon>
                            </v-btn> 
                          </template>
                          <span>Delete</span>
                        </v-tooltip>   
                       </td>

                    </tr>
                </template>


      </v-data-table>





  </v-form>  
</base-material-card>
  </v-container>    
</template>
<script>

import Documento from '../../services/documento'

  export default {
    name: 'Departamento',
   
    mounted () {
      this.lista();
    },


    

    data () {
      return {
        components: {
            DashboardCoreView: () => import('../dashboard/components/core/View'),
        },
        headers: [
          { text: 'Descrição', value: 'desricao' },
          { text: 'Empresa', value: 'empresa' },

        ],
        dialog:true,
        dlg_delete: false,
        alert_msg:false,
        showload_img: false,
        currentFile: undefined,
          progress: 0,
        msg_dialog:'',
        btn_load: false,
        departamentos: [],
        empresas: ["SEDE CORPORATIVA", "CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PARQUE DAS FLORES", "PREVIDA", "MELRO E SAMPARIO", "RESERVA DA PAZ",  "GPF - CORRETORA"],

        departamento:{
            descricao:'',
            empresa: '',
        },
        loading:false,
      }
    },

   
    methods: {

        clear () {
          this.$refs.form.reset();
        },
  

      ok_val(){
        this.$router.push('login');
      },

      setDelete(valor){
          this.value = valor;
          this.dlg_delete = true;
      },

      lista(){

        Documento.lista().then(response => {
                if(response.status = 200){
                  this.departamentos = response.data;
                }
              }).catch(e => {
              }).finally(() => {
            });

      },

      setConfirmDelete(){
          Documento.deleta(this.value.id).then(response => {
                if(response.status = 200){
                  let userIndex = this.departamentos.indexOf(this.value)
                  this.departamentos.splice(userIndex, 1);
                  this.msg_dialog = response.data;
                  this.alert_msg = true;
                }
              }).catch(e => {
                if(e.response.status === 401 || e.response.status === 403){
                      this.dlg_delete = false
                      this.msg_dialog = "Usuário não tem permissão para deletar";

              }else{
                this.msg_dialog = 'Problemas de Comunicação com o servidor, tente novamente mais tarde ou entre em contato com o Suporte'
              }
              }).finally(() => {
                this.value = {};
                this.dlg_delete = false;
                this.lista();

            });
      },
   
      salva(){
        this.btn_load = true;
        if(this.$refs.form.validate()){

              Documento.salva(this.departamento).then(response => {

                    if(response.data.codigo){
                        this.msg_dialog = response.data.mensagem;
                        this.alert_msg = true;
                     
                    }

                  }).catch(e => {

                   if(e == 'Error: timeout of 8000ms exceeded'){
                      this.loading = false
                      this.alert_msg = true;
                      this.btn_load = false;
                      this.msg_dialog = "Por Favor, Tente novamente ou verifique sua conexão";
                    }
                }).finally(() => {
                    this.loading = false
                    this.btn_load = false;
                    this.clear();
                    this.lista();
                    this.alert_msg = true;
                    this.msg_dialog = "Departamento cadastrado com sucesso!";

                  })
               
        }else{
            this.alert_msg = true;
            this.btn_load = false;
            this.msg_dialog = "Preencha todos os campos";

        }
      },



    }

  }


  
</script>